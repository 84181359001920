/* ------------- side bar --------------*/

.side-menu-wrapper {
    width: 200px;
    min-height: 100vh;
    background: #fff;
    color: #fff;
    position: fixed;
    margin-left: -200px;
    box-shadow: 2px 2px 2px -2px gray;
    transition: all 0.3s;
    z-index: 2050;
}

.page-wrapper {
    width: 100%;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}

.side-menu-wrapper.show {
    margin-left: 0px;
}

.nav {
    list-style-type: none;
}

.navbar-nav .dropdown-menu {
    background: #343a40;
}

.navbar-nav .dropdown-menu .dropdown-item:hover {
    background: #007bff;
}

.nav .nav-item .nav-link {
    padding: 15px 20px;
    font-size: 1em;
    display: block;
    color: #6e6e6e;
}

.nav .nav-item .nav-link:hover {
    color: #e40046;
}

.nav .nav-item .nav-link.active {
    background-color: #e40046;
    color: #ffffff;
}