.product-card {
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.product-card-image {
    justify-self: center;
    align-self: center;
    width: 100%;
}

.product-title-container {
    display: flex;
    justify-content: space-between;
}

.product-title {
    text-transform: capitalize;
}

.product-wishlist {
    color: #e5e5e5 !important;
    font-size: 20px !important;
    cursor: pointer;
}

.wished {
    color: #e40046 !important;
}

.product-vendor {
    text-transform: capitalize;
    color: #e40046;
    font-weight: 600;
}

.product-price-container {
    background: #f5f7fa;
    padding: 0.5rem 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.product-discount-price {
    color: #f17e0a;
    font-weight: 700;
    font-size: 1.5rem;
}

.product-price {
    text-transform: lowercase;
    margin-left: 0.5rem;
    text-decoration: line-through;
    color: #909399;
}

.product-percentage-discount {
    margin-left: 0.5rem;
    font-size: 0.8rem;
    padding: 0.2rem 0.7rem;
    background: #f17e0a;
    color: #ffffff;
    border-radius: 0.2rem;
}

.product-features {
    display: flex;
    margin-bottom: 0.5rem;
}

.product-features-title {
    text-transform: capitalize;
    color: #333333;
    width: 150px;
}

.feature-fulfillmemt {
    display: flex;
    flex-direction: column;
}

.feature-fulfillmemt .shop-card-product-features {
    margin-bottom: 0.5rem;
}

.feature-text {
    color: #6c757d;
    font-weight: 400;
}

.product-features svg {
    font-size: 1.2rem;
    color: #e40046;
    margin-right: 0.5rem;
}

.feature-color {
    padding: 0.2rem 0.5rem;
    height: 25px;
    color: #ffffff;
    font-size: 0.9rem;
    font-weight: 600;
}

.product-quantity {
    display: flex;
}

.product-quantity .btn-secondary {
    border-radius: 0;
    width: 40px;
    height: 30px !important;
    padding: 0rem;
    border: none;
}

.product-quantity .form-control {
    border-radius: 0;
    width: 80px;
    height: 30px !important;
}

.product-detail-info {
    font-size: 1rem;
    margin-bottom: 20px;
}
@media (max-width: 991px) {
    .product-detail-info {
        clear: both;
    }
}
.product-detail-info__categories a {
    background: #ff9221;
    padding: 2px 10px;
    margin-right: 5px;
    display: inline-block;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    margin-bottom: 5px;
}
.product-detail-info__categories a:hover {
    background: #f1592a;
}
.product-detail-info__title {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 15px;
}
.product-detail-info__table {
    width: 100%;
}
@media (max-width: 767px) {
    .product-detail-info__table {
        margin-top: 10px;
    }
}
.product-detail-info__table th {
    width: 1%;
    font-weight: normal;
    white-space: nowrap;
    padding-right: 0.6666666667rem;
}
.product-detail-info__table th .fa {
    color: #5db500;
    margin-right: 0.3333333333rem;
}
.product-detail-info__table td {
    padding: 2px;
}
@media (max-width: 767px) {
    .product-detail-info__table .manufacturers td,
    .product-detail-info__table .manufacturers th {
        border-top: 1px solid #e9e9e9;
        padding-top: 10px;
    }
}
@media (min-width: 768px) {
    .product-detail-info__table .model td,
    .product-detail-info__table .model th {
        border-top: 1px solid #e9e9e9;
        padding-top: 10px;
    }
}
.product-detail-info__table .stock td,
.product-detail-info__table .stock th {
    padding-bottom: 10px;
}
.product-detail-info__table .new-price td,
.product-detail-info__table .new-price th,
.product-detail-info__table .price td,
.product-detail-info__table .price th {
    padding-top: 10px;
    border-top: 1px solid #e9e9e9;
}
.product-detail-info__table .new-price h3,
.product-detail-info__table .price h3 {
    font-size: 2rem;
    color: #de0c0c;
    display: inline-block;
    margin: 0;
}
@media (max-width: 767px) {
    .product-detail-info__table .new-price h3,
    .product-detail-info__table .price h3 {
        font-size: 1.6rem;
    }
}
.product-detail-info__table .old-price {
    color: #333;
}
.product-detail-info__table .old-price span {
    text-decoration: line-through;
}
.product-detail-info__list {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 0;
    color: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
}
.product-detail-info__list .manufacturers_img {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 5%;
}
@media (max-width: 991px) {
    .product-detail-info__list .manufacturers_img {
        margin-right: 0;
    }
}
@media (max-width: 767px) {
    .product-detail-info__list .manufacturers_img {
        width: 30%;
        padding-left: 10px;
    }
}
.product-detail-info__list .manufacturers_img img {
    max-width: 100%;
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
}
.product-detail-info__list ul {
    margin: 0;
    margin-right: 20px;
}
@media (max-width: 767px) {
    .product-detail-info__list ul {
        width: 70%;
        margin-right: 0;
    }
}
.product-detail-info__list ul li {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 5px;
}
@media (max-width: 767px) {
    .product-detail-info__list ul li {
        width: 100%;
        margin-bottom: 7px;
    }
}
.product-detail-info__list ul li .fa {
    color: #5db500;
    margin-right: 5px;
}
.product-detail-info__list ul li:last-child {
    margin-bottom: 0;
}
.product-detail-info__price {
    display: table;
    width: 100%;
    margin: 0;
    margin: 15px 0 0;
}
@media (max-width: 767px) {
    .product-detail-info__price {
        display: block;
    }
}
.product-detail-info__price li,
.product-detail-info__price tr {
    display: table-row;
}
@media (max-width: 767px) {
    .product-detail-info__price li,
    .product-detail-info__price tr {
        display: block;
    }
}
.product-detail-info__price li div,
.product-detail-info__price li th,
.product-detail-info__price li > td,
.product-detail-info__price tr div,
.product-detail-info__price tr th,
.product-detail-info__price tr > td {
    display: table-cell;
    white-space: nowrap;
    padding-bottom: 7px;
    font-weight: normal;
}
@media (max-width: 767px) {
    .product-detail-info__price li div,
    .product-detail-info__price li th,
    .product-detail-info__price li > td,
    .product-detail-info__price tr div,
    .product-detail-info__price tr th,
    .product-detail-info__price tr > td {
        display: block;
        padding-bottom: 2px;
    }
}
.product-detail-info__price li div:first-child,
.product-detail-info__price li th:first-child,
.product-detail-info__price li > td:first-child,
.product-detail-info__price tr div:first-child,
.product-detail-info__price tr th:first-child,
.product-detail-info__price tr > td:first-child {
    width: 1px;
    padding-right: 10px;
}
@media (max-width: 767px) {
    .product-detail-info__price li div:first-child,
    .product-detail-info__price li th:first-child,
    .product-detail-info__price li > td:first-child,
    .product-detail-info__price tr div:first-child,
    .product-detail-info__price tr th:first-child,
    .product-detail-info__price tr > td:first-child {
        width: auto;
        text-align: left;
    }
}
.product-detail-info__price li div h2,
.product-detail-info__price li th h2,
.product-detail-info__price li > td h2,
.product-detail-info__price tr div h2,
.product-detail-info__price tr th h2,
.product-detail-info__price tr > td h2 {
    font-size: 2rem;
    color: #de0c0c;
    display: inline-block;
    margin: 0;
}
@media (max-width: 767px) {
    .product-detail-info__price li div h2,
    .product-detail-info__price li th h2,
    .product-detail-info__price li > td h2,
    .product-detail-info__price tr div h2,
    .product-detail-info__price tr th h2,
    .product-detail-info__price tr > td h2 {
        font-size: 1.6rem;
    }
}
.product-detail-info__price li.old-price,
.product-detail-info__price tr.old-price {
    color: #333;
}
@media (max-width: 767px) {
    .product-detail-info__price li.old-price,
    .product-detail-info__price tr.old-price {
        margin-top: 5px;
    }
}
.product-detail-info__price li.old-price div span,
.product-detail-info__price li.old-price th span,
.product-detail-info__price li.old-price > td span,
.product-detail-info__price tr.old-price div span,
.product-detail-info__price tr.old-price th span,
.product-detail-info__price tr.old-price > td span {
    text-decoration: line-through;
}
.product-detail-info__price hr {
    margin-bottom: 0;
}
.product-detail-info__other-price {
    border-top: 1px solid #ccc;
    padding-top: 15px;
    margin-top: 15px;
}
.product-detail-info__other-price + .product-detail-info__cash-back {
    margin-top: 5px;
}
.product-detail-info__cash-back {
    margin-top: 5px;
    margin-bottom: 25px;
}
.product-detail-info__cash-back__label {
    display: inline-block;
    font-weight: bold;
    text-align: left;
    background: red;
    color: #fff;
    padding: 5px 15px;
    padding-left: 35px;
    border-radius: 30px;
    white-space: nowrap;
    position: relative;
    background: #cf0404;
    background: -webkit-gradient(linear, left top, left bottom, from(#cf0404), to(#9b0303));
    background: linear-gradient(to bottom, #cf0404 0%, #9b0303 100%);
    margin-bottom: 0px;
    margin-top: 10px;
    display: inline-block;
}
.product-detail-info__cash-back__label:before {
    content: "$";
    width: 24px;
    height: 24px;
    background: #ffe000;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    color: #af0404;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: 24px;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
}
.product-detail-info__cash-back__label-detail {
    display: inline-block;
    margin-top: 5px;
    margin-left: 15px;
    color: #333;
    position: relative;
    z-index: 2;
}
.product-detail-info__cash-back__label-detail:hover {
    color: #f1592a;
}
.product-detail-info__action {
    padding: 0px;
    margin-top: 15px;
    font-size: 14px;
    border-radius: 5px;
}
@media (max-width: 767px) {
    .product-detail-info__action {
        text-align: center;
    }
}
.product-detail-info__action .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
}
.product-detail-info__action .row > div {
    padding: 0 5px;
}
.product-detail-info__action__quantity {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
}
@media (max-width: 767px) {
    .product-detail-info__action__quantity {
        display: block;
    }
}
.product-detail-info__action__quantity label {
    margin: 0;
    white-space: nowrap;
    padding-right: 10px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-size: 1rem;
}
.product-detail-info__action__quantity #input-quantity {
    height: 41px;
    line-height: 41px;
}
@media (max-width: 1199px) {
    .product-detail-info__action__quantity #input-quantity {
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
    }
}
@media (max-width: 767px) {
    .product-detail-info__action__quantity #input-quantity {
        width: calc(100vw - 101px);
        display: inline-block;
        line-height: 35px;
        height: 35px;
    }
}
.product-detail-info__action__call {
    margin-top: 10px;
    line-height: 40px;
    font-size: 1rem;
}
.product-detail-info__action__call b {
    font-size: 1.2rem;
}
.product-detail-info__action__call a {
    color: #f1592a;
    font-weight: bold;
    font-size: 1.2rem;
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}
.product-detail-info__action__call a:hover {
    text-decoration: underline;
}
.product-detail-info__action__call .fa {
    font-size: 2rem;
    position: relative;
    top: 7px;
}
.product-detail-info__action .btn-primary {
    font-weight: bold;
    text-transform: uppercase;
}
@media (max-width: 767px) {
    .product-detail-info__action .btn-primary {
        margin-top: 10px;
    }
}
.product-detail-info__action .btn-primary .fa {
    font-size: 18px;
}
.product-detail-info__action .btn-secondary {
    width: max-content;
}
@media (max-width: 767px) {
    .product-detail-info__action .btn-secondary {
        margin-top: 10px;
    }
}
.product-detail-info__action .btn-secondary .fa {
    font-size: 18px;
    margin-right: 5px;
}
.product-detail-info__help {
    margin-top: 20px;
    font-size: 0px;
}
.product-detail-info__help a {
    display: inline-block;
    width: 50%;
    font-size: 1rem;
    vertical-align: top;
    font-weight: bold;
    color: #333;
    margin-bottom: 7px;
}
@media (max-width: 767px) {
    .product-detail-info__help a {
        width: 100%;
    }
}
.product-detail-info__help a .fa {
    font-size: 15px;
    margin-right: 10px;
}
.product-detail-info__help a:hover {
    color: #f1592a;
}
.product-detail-info__features {
    margin-left: -5px;
    margin-right: -5px;
    list-style: none;
    padding: 0;
    margin-top: 10px;
    font-size: 0;
}
.product-detail-info__features li {
    font-size: 13px;
    width: 50%;
    padding: 5px;
    display: inline-block;
    text-transform: uppercase;
}
@media (max-width: 767px) {
    .product-detail-info__features li {
        width: 100%;
    }
}
.product-detail-info__features__item {
    background: #eee;
    padding: 10px;
    border-radius: 5px;
}
.product-detail-info__features__item img {
    height: 25px;
    margin-right: 10px;
    float: left;
    margin-top: -7px;
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
}
.product-detail-info #product hr:first-child {
    margin-top: 0;
}
.product-detail-info .manufacturers_img {
    float: right;
    position: absolute;
    right: 1rem;
    font-size: 3em;
}
@media (max-width: 991px) {
    .product-detail-info .manufacturers_img {
        font-size: 1.5rem;
    }
}

@media (max-width: 767px) {
    .product-detail-info .manufacturers_img {
        font-size: 1.5rem;
    }
}
.product-detail-content {
    line-height: 1.4;
    padding: 0px;
    margin-bottom: 30px;
    overflow: hidden;
    text-align: justify;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    -webkit-box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
}
.product-detail-content .title-section {
    margin-top: 0;
}
.product-detail-content h2 {
    font-size: 1.2rem;
}
.product-detail-content h3 {
    font-size: 1.0666666667rem;
}
.product-detail-content img {
    max-width: 100%;
    height: auto;
}
.product-detail-content a {
    font-weight: bold;
    color: #333;
}
.product-detail-content a:hover {
    color: #f1592a;
}
.product-detail-content iframe {
    max-width: 100%;
}
.product-detail-content ul li {
    line-height: 1.6;
}
.product-detail-content.-download ul {
    list-style: none;
    padding: 0;
    margin: 0px;
}
.product-detail-content.-download ul li {
    margin-bottom: 10px;
}
.product-detail-content.-download ul li .fa {
    width: 30px;
    height: 30px;
    background: #eee;
    border-radius: 5px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    text-align: center;
    line-height: 30px;
    font-size: 18px;
    color: #000;
}
.product-detail-content.-review .top-rating {
    margin-top: 0;
}
.product-detail-content.-review .top-rating > .stars.col-sm-7.col-xs-12 {
    width: 100%;
    padding: 0;
}
.product-detail-content.-review .top-rating > .col-sm-5.col-xs-12 {
    width: 100%;
    padding: 0;
}
.product-detail-content.-review .top-rating > .col-sm-5.col-xs-12 > div {
    padding-top: 20px;
}
.product-detail-content.-review .stars {
    margin-bottom: 15px;
}
.product-detail-content.-review .star-rating .progress {
    margin-bottom: 2px;
    height: 10px;
}
.product-detail-content.-review#tab-review .table {
    border: 0 !important;
}
.product-detail-content.-review#tab-review .table > tbody > tr > td {
    border: 0 !important;
}
.product-detail-content.-review#tab-review .rating {
    border: 0 !important;
}

.category-description,
.infomation-description,
.manufacturer-description {
    line-height: 1.4;
    text-align: justify;
}
.category-description h2,
.infomation-description h2,
.manufacturer-description h2 {
    font-size: 1.2rem;
}
.category-description a,
.infomation-description a,
.manufacturer-description a {
    font-weight: bold;
    color: #333;
}
.category-description a:hover,
.infomation-description a:hover,
.manufacturer-description a:hover {
    color: #f1592a;
}
.title-section {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    background: #ddd;
    line-height: 1;
}
.title-section:before {
    content: "";
    display: block;
    position: absolute;
    top: 0%;
    left: 0;
    width: 15px;
    height: 100%;
    z-index: 3;
    background: #f1592a;
}
.title-section h3 {
    display: inline-block;
    background: #fff;
    font-size: 1.6rem;
    font-weight: bold;
    color: #f1592a;
    position: relative;
    z-index: 1;
    padding-right: 20px;
    padding-left: 30px;
    margin: 0;
}
@media (max-width: 991px) {
    .title-section h3 {
        font-size: 1.3333333333rem;
    }
}
.flickity-prev-next-button.previous {
    left: -50px;
}
.flickity-prev-next-button.next {
    right: -50px;
}
.flickity-prev-next-button:disabled {
    opacity: 0;
    pointer-events: none;
}
.product-carousel {
    margin-bottom: 20px;
    margin: auto;
}
@media (max-width: 767px) {
    .product-carousel .flickity-viewport {
        overflow: visible;
    }
}
.product-carousel .flickity-prev-next-button {
    margin-top: -30px;
}
.product-carousel .flickity-page-dots {
    position: static;
    margin-top: 20px;
    margin-bottom: 20px;
}
.product-carousel .product-grid {
    margin-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
}
@media (min-width: 1200px) {
    .product-carousel .product-grid {
        width: 20%;
    }
}
@media (max-width: 767px) {
    .product-carousel .product-grid {
        width: 300px;
        max-width: 165px;
    }
}
@media (max-width: 360px) {
    .product-carousel .product-grid {
        max-width: 145px;
    }
}
.product-carousel .product-grid:first-child {
    margin-left: 0;
}
.product-carousel.flickity-loaded .product-grid {
    min-height: 100%;
}
.product-oneway-related-carousel {
    margin-left: -15px;
    margin-right: -15px;
}
@media (min-width: 1200px) {
    .product-oneway-related-carousel .product-grid {
        width: 33.33%;
    }
}
.product-oneway-related-carousel .product-grid .product-thumb {
    border: 0;
}
.product-oneway-related-carousel .flickity-page-dots {
    margin-bottom: 0;
}
.tag-list a {
    background: #eee;
    padding: 2px 10px;
    margin-right: 5px;
    display: inline-block;
    color: #555;
    border-radius: 5px;
    font-size: 12px;
    margin-bottom: 5px;
}
.tag-list a:hover {
    background: #f1592a;
    color: #fff;
}

.product-contact {
    border: 1px solid #f1592a;
    border-radius: 5px;
    padding: 0px;
    margin-top: 20px;
    overflow: hidden;
    text-align: left;
}
.product-contact__hotline {
    font-weight: bold;
    color: #f1592a;
    padding: 5px;
}
.product-contact__address {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    padding: 5px;
    max-width: 345px;
}
@media (max-width: 1199px) {
    .product-contact__address {
        max-width: 240px;
    }
}
@media (max-width: 991px) {
    .product-contact__address {
        max-width: none;
    }
}
@media (max-width: 767px) {
    .product-contact__address {
        max-width: calc(100vw - 247px);
    }
}
.product-contact ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: table;
    width: 100%;
}
.product-contact ul li {
    display: table-row;
    white-space: nowrap;
}
.product-contact ul li:nth-child(2n) {
    background: #f5f5f5;
}
.product-contact ul li strong {
    min-width: 85px;
    display: table-cell;
    padding: 5px;
}
.product-contact ul li a {
    display: table-cell;
}
.product-contact ul li i {
    margin-right: 5px;
}

.product-thumb {
    border: 1px solid #ddd;
    margin-bottom: 20px;
    /* overflow: auto; */
}
.product-thumb .image {
    text-align: center;
}
.product-thumb .image a {
    display: block;
}
.product-thumb .image a:hover {
    opacity: 0.8;
}
.product-thumb .image img {
    margin-left: auto;
    margin-right: auto;
}
.product-grid .product-thumb .image {
    float: none;
}
@media (min-width: 767px) {
    .product-list .product-thumb .image {
        float: left;
        padding: 0 15px;
    }
}
.product-thumb h4 {
    font-weight: bold;
}
.product-thumb .caption {
    padding: 0 20px;
    min-height: 180px;
}
.product-list .product-thumb .caption {
    margin-left: 230px;
}
@media (max-width: 1200px) {
    .product-grid .product-thumb .caption {
        min-height: 210px;
        padding: 0 10px;
    }
}
@media (max-width: 767px) {
    .product-list .product-thumb .caption {
        min-height: 0;
        margin-left: 0;
        padding: 0 10px;
    }
    .product-grid .product-thumb .caption {
        min-height: 0;
    }
}
.product-thumb .rating {
    padding-bottom: 10px;
}
.rating .fa-stack {
    font-size: 8px;
}
.rating .fa-star-o {
    color: #999;
    font-size: 15px;
}
.rating .fa-star {
    color: #fc0;
    font-size: 15px;
}
.rating .fa-star + .fa-star-o {
    color: #e69500;
}
h2.price {
    margin: 0;
}
.product-thumb .price {
    color: #444;
}
.product-thumb .price-new {
    font-weight: 600;
}
.product-thumb .price-old {
    color: #999;
    text-decoration: line-through;
    margin-left: 10px;
}
.product-thumb .price-tax {
    color: #999;
    font-size: 12px;
    display: block;
}
.product-thumb .button-group {
    border-top: 1px solid #ddd;
    background-color: #eee;
    overflow: auto;
}
.product-list .product-thumb .button-group {
    border-left: 1px solid #ddd;
}
@media (max-width: 768px) {
    .product-list .product-thumb .button-group {
        border-left: none;
    }
}
.product-thumb .button-group button {
    width: 60%;
    border: none;
    display: inline-block;
    float: left;
    background-color: #eee;
    color: #888;
    line-height: 38px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}
.product-thumb .button-group button + button {
    width: 20%;
    border-left: 1px solid #ddd;
}
.product-thumb .button-group button:hover {
    color: #444;
    background-color: #ddd;
    text-decoration: none;
    cursor: pointer;
}
@media (max-width: 1200px) {
    .product-thumb .button-group button,
    .product-thumb .button-group button + button {
        width: 33.33%;
    }
}
@media (max-width: 767px) {
    .product-thumb .button-group button,
    .product-thumb .button-group button + button {
        width: 33.33%;
    }
}
