.react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.react-pdf__Page {
    width: 80% !important;
}
.react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
}
