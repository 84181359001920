/* ------------- shop product cards --------------*/

.shop-card-container {
    background-color: #fff;
    padding: 0;
    margin-left: -1px;
    margin-top: -1px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    z-index: 0;
    text-align: left;
    border: 1px solid transparent;
}

.shop-card-container:hover {
    -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 2;
    border-color: #f1592a;
    text-decoration: none;
}

.shop-card {
    height: 100%;
}

.shop-card .shop-card-image {
    text-align: center;
    position: relative;
    padding-top: 0.5rem;
    /* height: 12rem; */
}

.shop-card .shop-card-image a {
    display: block;
    height: 12rem;
}

.shop-card .shop-card-image img {
    height: 100%;
    max-width: 100%;
}

.shop-card .shop-card-sale {
    color: #fff;
    background-color: #e40046;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 2px 7px;
    display: block;
    position: absolute;
    top: 10px;
    left: 0;
}

.shop-card .shop-card-wishlist {
    position: absolute;
    padding: 2px 7px;
    background-color: transparent;
    color: #e5e5e5 !important;
    font-size: 20px !important;
    left: auto;
    cursor: pointer;
    top: 3px;
    right: 0;
}

.shop-card .wished {
    color: #e40046 !important;
}

.shop-card .shop-card-content {
    /* text-align: center; */
    padding: 15px 5px 5px;
    margin: 0 auto;
}

.shop-card .shop-card-vendor {
    color: #878787;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
}

.shop-card .shop-card-title {
    width: 100%; /* IE6 needs any width */
    overflow: hidden; /* "overflow" value must be different from  visible"*/
    -o-text-overflow: ellipsis; /* Opera < 11*/
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    color: #212121;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    margin: 5px 0 5px;
}

.shop-card .shop-card-ratings-container {
    margin-bottom: 5px;
    font-size: 12px;
    position: relative;
}

.shop-card .shop-card-ratings-container > span {
    margin-right: 5px;
}

.full-star-icon,
.half-star-icon {
    color: #fbb023;
}

.empty-star-icon {
    color: #999;
}

.total-rating-votes {
    position: relative;
    bottom: -2px;
    color: #999;
    margin-left: 5px !important;
}

.shop-card .shop-card-title a:hover,
.shop-card:hover .shop-card-title a {
    color: #e40046;
    text-decoration: none;
}

.shop-card .shop-card-discount-price {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 700;
    color: #e65400;
}

.shop-card .shop-card-discount {
    position: absolute;
    bottom: -10px;
    right: 5px;
    font-size: 12px;
    background-color: #e65400;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    box-sizing: content-box;
    font-family: Inter, sans-serif;
    font-weight: 500;
}

.shop-card .shop-card-price-container {
}

.shop-card .shop-card-price {
    color: #868e96;
    font-size: 13px;
    font-weight: 400;
    text-decoration: line-through;
    margin-left: 3px;
    display: inline-block;
    padding-left: 5px;
}

.shop-card .shop-card-features-container {
    margin-top: 2px;
    margin-bottom: 10px;
}

.shop-card .shop-card-product-features {
    font-size: 18px;
    color: #e40044bc;
    padding-right: 25px;
}

.shop-btn-primary {
    /* color: #f17e0a; */
    color: #fff;
    font-size: 16px;
    background-color: #f17e0a;
    /* background-color: rgb(11, 144, 173) !important; */
    font-weight: 600;
    border-color: #f17e0a;
    border-radius: 0;
    font-family: Inter, sans-serif;
    font-weight: 400;
}

.shop-btn-primary:focus,
.shop-btn-primary.focus,
.shop-btn-primary:hover,
.shop-btn-primary:active,
.shop-btn-primary:active:focus,
.shop-btn-primary.active {
    color: #ffffff;
    background-color: #f17e0a;
}

/*cart and checkout buttons*/

.shop-btn-secondary,
.shop-btn-secondary:focus,
.shop-btn-secondary.focus,
.shop-btn-secondary:hover,
.shop-btn-secondary:active,
.shop-btn-secondary:active:focus,
.shop-btn-secondary.active {
    color: #fff;
    font-size: 1rem;
    background-color: #f17e0a;
    font-weight: 600;
    border-color: #f17e0a;
}
