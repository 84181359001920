body {
    margin: 0;
    padding: 0;
    background: #fff;
    font-family: "Roboto", "Arial", "Open Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.btn-primary {
    border-color: transparent !important;
}

.btn-secondary {
    /* color: #000 !important; */
    background-color: #6c757d;
    border-color: transparent !important;
}
