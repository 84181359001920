.nav-item {
    margin: 0px 8px;
}

.nav-item .nav-link {
    color: white;
}

@media (max-width: 768px) {
    .main-navbar {
        display: none !important;
    }
}
