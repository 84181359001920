.zoom-image-container {
    overflow: hidden;
}

.zoom-image {
    transition: transform 0.5s ease;
    width: 100%;
    height: auto;
    transform-origin: center center;
    transform: scale(1);
}

.zoom-image:hover {
    transform: scale(1.4);
}
