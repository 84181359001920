/* item banners section*/
.banners-section {
    position: relative;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 40px;
}
.item-banners {
}

.item-container {
    width: 100%;
}

.item-content {
    align-self: center;
    background-color: transparent;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 4% 5%;
    transition: all 0.25s ease-in-out;
}

.item-banner-title {
    color: #f17e0a;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 0;
}

.banner-image {
    z-index: 4;
    width: 100%;
    max-width: 100%;
    transition: all 0.6s ease-in-out;
}
