.swiper-section {
    padding-top: 60px;
    padding-bottom: 60px;
    display: none;
}

@media screen and (min-width: 576px) {
    .swiper-section {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .swiper-menu {
        max-width: 540px;
    }
}

@media screen and (min-width: 768px) {
    .swiper-section {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .swiper-menu {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .swiper-menu {
        max-width: 960px;
    }

    .swiper-section {
        display: block;
    }
}

.swiper-menu {
    width: 100%;
    padding-right: calc(15px);
    padding-left: calc(15px);
    margin-right: auto;
    margin-left: auto;
}

.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.swiper-wrapper {
    /* transition-duration: 0ms;
    transform: translate3d(-1920px, 0px, 0px); */
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform, -webkit-transform;
    box-sizing: content-box;
}

.swiper-slide {
    width: 210px;
    margin-right: 30px;
    height: 100%;
    position: relative;
    flex-shrink: 0;
    transition-property: transform, -webkit-transform;
}

.swiper-slide a {
    margin: auto;
    width: 180px;
    height: 180px;
    display: flex;
    position: relative;
    -webkit-box-pack: center;
    justify-content: center;
    transition: all 0.4s ease 0s;
    border-radius: 50%;
    border: 2px solid rgb(67, 71, 78);
    text-decoration: none;
}

.swiper-slide a:hover {
    border-color: transparent;
    color: rgb(255, 112, 4);
}

.swiper-slide a:hover::after {
    opacity: 1;
    animation: 9s linear 0s infinite normal none running bZoBR;
}

.swiper-slide a::after {
    content: "";
    inset: -1px;
    opacity: 0;
    z-index: -1;
    position: absolute;
    transition: all 0.4s ease 0s;
    border-radius: 50%;
    border: 2px dashed rgb(255, 112, 4);
}

@keyframes bZoBR {
    100% {
        transform: rotateZ(360deg);
    }
}

.slide-item {
    align-self: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slide-text {
    margin: 12px 0px 0px;
    color: rgb(71, 71, 71);
    transition: all 0.4s ease 0s;
    font-weight: 600;
}

.slide-menu-img {
    width: 75px;
    height: 75px;
}
