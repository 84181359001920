/* general */

.card {
    border: 0px;
    border-radius: 0;
}

.card-img-top {
    border-radius: 0;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important;
}

.btn-primary,
.btn-primary:disabled {
    background-color: #333333;
    border-color: #333333;
    color: #ffffff;
    border-radius: 0;
}

.btn-primary:disabled {
    cursor: not-allowed;
}

.btn-primary:focus,
.btn-primary.focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary:active:focus {
    background-color: #f17e0a !important;
    border-color: #f17e0a !important;
    color: #ffffff !important;
}

.form-control {
    border-radius: 0rem;
}

/* banner section */

.main-banner-container {
    max-height: 500px;
}

.main-banner-content {
    padding: 2rem;
    background: #ffffff;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.main-banner-text {
    align-self: center;
}

.main-banner-title {
    font-weight: 500;
    color: #f17e0a !important;
}

.main-banner-image {
    height: inherit;
    justify-self: end;
}

/* sellout section */
.sellout-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 1rem;
}

.sellout-card {
    align-items: center;
    padding: 1.25rem 2rem;
}

.sellout-icon {
    height: 60px;
}
.sellout-title {
    font-weight: bold;
}
/* products section */
.products-section-title {
    display: flex;
    justify-content: space-between;
}

.products-section-link {
    color: #333333;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
}

.products-section-link:hover {
    color: #f17e0a;
    text-decoration: none;
}

.products-container {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 1rem;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.products-container .card-title {
    text-align: center;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    text-transform: capitalize;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.products-container .card-text {
    text-align: center;
    color: #f17e0a;
    font-weight: 600;
}

/* deals section */
.deals-content {
    height: 450px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    background: #ffffff;
}

.deals-image {
    max-height: 400px;
    align-self: center;
    justify-self: center;
}

.deals-text {
    padding: 0 75px;
    /* color: #ffffff !important; */
    height: 100%;
    display: grid;
    align-content: center;
    justify-content: center;
    background: rgba(241, 126, 10, 0.5);
    background: #eee8aa;
}

/* newsletter section */
.newsletter-contaner {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f6f7fb;
    padding: 40px 20px;
}

.input-contaner {
    width: 500px;
}

@media (max-width: 576px) {
    /* deals section */
    .deals-image {
        height: 150px;
    }

    .deals-text {
        padding: 1rem 2rem;
    }

    /* newsletter section */
    .newsletter-contaner {
        padding: 30px;
    }
    .input-contaner {
        width: 90%;
    }

    .newsletter-contaner .text-muted {
        text-align: justify;
    }
}

@media (max-width: 768px) and (min-width: 567px) {
    /* sellout section */
    .sellout-section {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
    }

    /* deals section */
    .deals-image {
        height: 250px;
    }

    .deals-text {
        padding: 1rem 1rem;
        text-align: center;
    }

    .deals-text .text {
        text-align: justify;
    }

    /* products section */
    .products-container {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    /* banner section */
    .main-banner-container {
        max-height: auto;
    }
    .main-banner-content {
        display: block;
    }

    .main-banner-title {
        font-size: 3rem;
    }

    .lead {
        font-size: 1rem;
    }

    .main-banner-image {
        display: none;
    }

    /* item banners section */
    .item-banner-title {
        font-size: 0.8rem;
    }

    .item-container h4 {
        font-size: 1rem;
    }

    .banner-image {
        height: 150px;
    }

    /* deals section */
    .deals-content {
        height: auto;
    }

    .deals-title {
        margin-top: 1rem;
        font-size: 1.5rem;
    }
}

@media (max-width: 992px) and (min-width: 769px) {
    /* banner section */
    .main-banner-image {
        height: 250px;
        justify-self: center;
        align-self: center;
    }
    .main-banner-title {
        font-size: 2rem;
    }
    /* sellout section */
    .sellout-section {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
    }

    /* item banners section*/
    .banner-image {
        height: 150px;
    }

    .item-banner-title {
        font-size: 0.8rem;
    }

    .item-container h4 {
        font-size: 0.9rem;
    }

    /* deals section */
    .deals-text {
        padding: 0 1rem;
    }
    .deals-title {
        font-size: 2rem;
    }
}

@media (max-width: 1200px) and (min-width: 993px) {
    /* item banners section*/
    .banner-image {
        height: 250px;
    }

    /* products section */
    .products-container {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
}
